import {
  ComponentPropsWithoutRef,
  ElementRef,
  ReactNode,
  forwardRef
} from 'react'
import * as RadixForm from '@radix-ui/react-form'
import { Flex } from '@radix-ui/themes'
import { DataTestIDs } from 'shared/constants'
import styles from './amount-field.module.scss'

export const AmountValidationMessages = () => {
  return (
    <Flex
      gap='2'
      width={'auto'}
      wrap={'nowrap'}
    >
      <ValidationMessage
        match='valueMissing'
        dataTestID={DataTestIDs.validationMessageAmountMismatch}
      >
        Enter your amount
      </ValidationMessage>

      <ValidationMessage
        match='typeMismatch'
        dataTestID={DataTestIDs.validationMessageTypeMismatch}
      >
        Enter a valid amount
      </ValidationMessage>

      <ValidationMessage
        match='rangeUnderflow'
        dataTestID={DataTestIDs.validationMessageAmountSmall}
      >
        Amount is too small
      </ValidationMessage>

      <ValidationMessage
        match='rangeOverflow'
        dataTestID={DataTestIDs.validationMessageAmountLarge}
      >
        Amount is too large
      </ValidationMessage>

      <ValidationMessage
        match='badInput'
        dataTestID={DataTestIDs.validationMessageBadInput}
      >
        Try another value
      </ValidationMessage>
    </Flex>
  )
}

interface MessageProps
  extends ComponentPropsWithoutRef<typeof RadixForm.Message> {
  children?: ReactNode
  dataTestID?: DataTestIDs | ''
}
const ValidationMessage = forwardRef<
  ElementRef<typeof RadixForm.Message>,
  MessageProps
>(({ children, match, dataTestID, ...props }, ref) => {
  return (
    <RadixForm.Message
      {...props}
      match={match}
      ref={ref}
      className={styles.amountFieldErrorMessage}
      data-testid={dataTestID}
    >
      {children}
    </RadixForm.Message>
  )
})
