import { Table, Flex, Button } from '@radix-ui/themes'
import { DataTestIDs } from 'shared/constants'
import styles from '../../table.module.scss'

export const OpenDetailsCell = ({
  onClick,
  dataTestID = ''
}: {
  onClick?: () => void
  dataTestID?: DataTestIDs | ''
}) => {
  return (
    <Table.Cell className={styles.detailsCell}>
      <Flex
        align={'center'}
        height={'100%'}
      >
        <Button
          variant='ghost'
          onClick={onClick}
          m={'0'}
          mt={'-3'}
          data-testid={dataTestID}
        >
          Open details
        </Button>
      </Flex>
    </Table.Cell>
  )
}
