import {
  readContract as readContractWagmi,
  estimateGas as estimateGasWagmi,
  writeContract,
  getGasPrice
} from '@wagmi/core'
import { wagmiConfig } from 'app/wagmi-config'
import { addressList } from 'contracts/address'
import { getTransactionStatus } from 'contracts/utils/get-transaction-status'
import { Web3Adress } from 'shared/types/web3'
import { formatWeb3Price } from 'shared/utils/format-web3-price'
import { parseEther } from 'viem'
import { Config, UseAccountReturnType } from 'wagmi'
import { abi } from './abi'

export const mintUsdc = async (
  account: UseAccountReturnType<Config>,
  amount: string
) => {
  await writeContract(wagmiConfig, {
    abi,
    address: addressList.usdc as Web3Adress,
    args: [account.address as Web3Adress, parseEther(amount)],
    functionName: 'mint'
  })

  return readContractWagmi(wagmiConfig, {
    abi,
    address: addressList.usdc as Web3Adress,
    functionName: 'balanceOf',
    args: [account.address as Web3Adress]
  })
}

export const allowance = async (account: UseAccountReturnType<Config>) => {
  return readContractWagmi(wagmiConfig, {
    abi,
    address: addressList.usdc,
    functionName: 'allowance',
    args: [account.address as Web3Adress, addressList.treasury]
  })
}

export const approve = async (amount: number) => {
  const result = await writeContract(wagmiConfig, {
    abi,
    address: addressList.usdc,
    functionName: 'approve',
    args: [addressList.treasury, parseEther(String(amount))]
  })

  return getTransactionStatus(result)
}

export const estimateFee = async (account: UseAccountReturnType<Config>) => {
  const gasPrice = await getGasPrice(wagmiConfig)

  const gas = await estimateGasWagmi(wagmiConfig, {
    account: account.address
  })

  return formatWeb3Price(gas * gasPrice).toFixed(7)
}

export const getBalance = (address: string) =>
  readContractWagmi(wagmiConfig, {
    abi,
    address: addressList.usdc as Web3Adress,
    functionName: 'balanceOf',
    args: [address as Web3Adress]
  })
