import React from 'react'
import { useReactiveVar } from '@apollo/client'
import { Box, DropdownMenu, Flex, Text } from '@radix-ui/themes'
import {
  AVATAR_FALLBACK_DEFAULT,
  DataTestIDs,
  NAME_FALLBACK_DEFAULT,
  RouterPathes
} from 'shared/constants'
import { userVar } from 'shared/store/user'
import { XyroAvatar } from 'shared/ui'
import { PROFILE_LINK_ICONS } from '../constants'
import { ProfileMenuItem } from './drop-down-link'
import { Logout } from './logout'
import styles from '../profile.module.scss'

export const ProfileContent: React.FC = () => {
  const user = useReactiveVar(userVar)

  if (!user) return

  const userName = user?.name ?? NAME_FALLBACK_DEFAULT

  return (
    <DropdownMenu.Content className={styles.dropDownContent}>
      <Flex
        gap='2'
        className={styles.dropDownWrapper}
        align={'center'}
        px={'2'}
        mb={'1'}
        data-testid={DataTestIDs.profileHeaderContent}
      >
        <XyroAvatar
          src={user.avatarUris[0] || ''}
          fallback={user.name[0] || AVATAR_FALLBACK_DEFAULT}
          size={'4'}
        />
        <Flex
          direction={'column'}
          justify={'center'}
        >
          <Text
            weight='bold'
            className={styles.userName}
          >
            {userName}
          </Text>
        </Flex>
      </Flex>

      <Box
        className={styles.separator}
        mt={'4'}
        mb={'1'}
      />

      <Flex
        direction={'column'}
        gap={'2'}
      >
        <ProfileMenuItem
          link={RouterPathes.profile}
          title='Profile'
          icon={PROFILE_LINK_ICONS.PROFILE_ICON}
          dataTestID={DataTestIDs.buttonProfileLink}
        />

        <ProfileMenuItem
          link={RouterPathes.settings}
          title='Settings'
          icon={PROFILE_LINK_ICONS.SETTINGS_ICON}
          dataTestID={DataTestIDs.buttonSettingsLink}
        />
      </Flex>

      <Box
        className={styles.separator}
        my={'1'}
      />

      <Logout />
    </DropdownMenu.Content>
  )
}
