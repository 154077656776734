export enum DataTestIDs {
  // NOTE: Naming structure for common cases
  // [element][place(position)][action?]

  buttonLoginHeader = 'btnLoginHeader',
  buttonConnectWalletHeader = 'btnConnectWalletHeader',
  balanceValueHeader = 'balanceValueHeader',
  buttonRewardsHeader = 'headerRewards',
  buttonApprovedBalanceHeader = 'headerApprovedBalance',
  buttonGlobalChatHeader = 'headerOpenGlobalChat',
  buttonProfileHeader = 'headerProfileAvatar',
  buttonGlobalChatClose = 'btnHideGlobalChat',
  buttonGlobalChatSendMessage = 'sendMsgToGlobalChat',
  buttonProfileLink = 'btnProfile',
  buttonSettingsLink = 'btnSettings',
  buttonLogOut = 'btnLogOut',
  profileHeaderContent = 'profileHeaderContent',
  buttonConnectViaMM = 'connectViaMM',
  connectWalletModal = 'connectWalletModal',
  signUpModal = 'signUpModal',
  buttonUseRefferalCode = 'btnUseRefCode',
  refferalCodeInput = 'refCodeInput',
  buttonContinue = 'btnContinue',
  upDownSidePanel = 'upDownSidePanel',
  bullsEyeSidePanel = 'bullsEyeSidePanel',
  oneVsOneSidePanel = 'oneVsOneSidePanel',
  setupsSidePanel = 'setupsSidePanel',
  rewardsSidePanel = 'rewardsSidePanel',
  upDownGameStatus = 'upDownGameStatus',
  upDownAssetPrice = 'upDownAssetPrice',
  buttonBetUpUpDown = 'upDownBetUp',
  buttonBetDownUpDown = 'upDownBetDown',
  resultUpDownUpWins = 'resultUpWins',
  resultUpDownDownWins = 'resultDownWins',
  createNameInput = 'createNameInput',
  buttonSaveCreatedName = 'btnSaveCreatedName',
  buttonHeaderGetStarted = 'btnGetStarted',
  buttonHeaderConnectWallet = 'btnConnectWallet',
  buttonUpDownConnectWallet = 'btnUpDownConnectWallet',
  buttonUpDownSignInToPlay = 'btnUpDownSignInToPlay',
  buttonUpDownSwitchChain = 'btnUpDownSwitchChain',
  bullsEyeGraphHeaderStatus = 'bullsEyeGameStatus',
  bullsEyeGraphAssetPrice = 'bullsEyeAssetPrice',
  inputBullsEyePredict = 'bullsEyePredictInput',
  buttonBullsEyeMakePredict = 'bullsEyePlaceBid',
  bullsEyeWinnerItem = 'bullsEyeWinner',
  validationMessageAmountMismatch = 'validationMessageAmountMismatch',
  validationMessageTypeMismatch = 'validationMessageTypeMismatch',
  validationMessageAmountLarge = 'validationMessageAmountLarge',
  validationMessageAmountSmall = 'validationMessageAmountSmall',
  validationMessageBadInput = 'validationMessageBadInput',
  buttonBullsEyeConnectWallet = 'btnBullsEyeConnectWallet',
  buttonBullsEyeSignInToPlay = 'btnBullsEyeSignInToPlay',
  buttonBullsEyeSwitchChain = 'btnBullsEyeSwitchChain',
  buttonOneVsOneSelectAsset = 'oneVsOneSelectAsset',
  buttonOneVsOneSelectAssetTrigger = 'oneVsOneAssetDropDown',
  inputOneVsOnePredictPrice = 'oneVsOnePredictPriceInput',
  inputOneVsOnePredictAmount = 'oneVsOneBidPriceInput',
  buttonOneVsOnePlay = 'oneVsOneBtnPlay',
  buttonOneVsOneConnectWallet = 'buttonOneVsOneConnectWallet',
  buttonOneVsOneSignInToPlay = 'buttonOneVsOneSignInToPlay',
  buttonOneVsOneSwitchChain = 'buttonOneVsOneSwitchChain',
  oneVsOneSelectedAssetName = 'oneVsOneAssetName',
  oneVsOneSelectedAssetPrice = 'oneVsOneAssetPrice',
  buttonOneVsOneSwitchToGlobal = 'oneVsOneBtnSwitchToGlobal',
  buttonOneVsOneSwitchToPersonal = 'oneVsOneBtnSwitchToPersonal',
  buttonOneVsOneSwitchToCurrent = 'oneVsOneBtnSwitchToCurrent',
  buttonOneVsOneSwitchToCompleted = 'oneVsOneBtnSwitchToCompleted',
  buttonOneVsOneSwitchToExpired = 'oneVsOneBtnSwitchToExpired',
  buttonOneVsOneAccept = 'oneVsOnebtnAccept',
  buttonOneVsOneDeny = 'oneVsOnebtnDeny',
  buttonOneVsOneOpenDetails = 'oneVsOneOpenDetails',
  oneVsOneUserAcceptBid = 'oneVsOneUserAcceptBid',
  buttonOneVsOneAcceptGame = 'oneVsOneAcceptGame',
  inputOneVsOneAcceptModalPredictPrice = 'oneVsOneAcceptModalPredictInput',
  buttonOneVsOneConfirmGame = 'oneVsOneConfirmGame',
  buttonOneVsOneRejectGame = 'oneVsOneRejectGame'
}
