import { FC } from 'react'
import { useReactiveVar } from '@apollo/client'
import { Flex, Text } from '@radix-ui/themes'
import { featureFlags } from 'app/app-feature-flags'
import { BoldPlusIcon } from 'shared/icons'
import { balanceVar } from 'shared/store/balance-store'
import { TetherToken, XyroButton } from 'shared/ui'
import { formatToXyro } from 'shared/utils/format-price'
import { isMintDialogOpenVar } from '../store/dialogs-balance-store'
import { MintDialog } from './mint-dialog'
import styles from '../balance-add.module.scss'
import { DataTestIDs } from 'shared/constants'

const { tokenMintAllowed } = featureFlags

interface Props {
  loading: boolean
}
export const BalanceAddButton: FC<Props> = ({ loading }) => {
  const balance = useReactiveVar(balanceVar)
  const handleDepositDialogOpenning = async () => {
    isMintDialogOpenVar(true)
  }

  const balanceFormat = loading ? '---' : formatToXyro(balance, 'long')

  return (
    <Flex
      align={'center'}
      gap={{ initial: '2', xs: '3' }}
      direction={'row'}
    >
      {tokenMintAllowed && (
        <XyroButton
          className={styles.balanceAddButton}
          onClick={handleDepositDialogOpenning}
          shape='cutted-both'
          color='green'
          isIconOnly
        >
          <BoldPlusIcon
            color='var(--black)'
            width={'100%'}
            height={'100%'}
          />
        </XyroButton>
      )}

      <Flex direction={'column'}>
        <Text
          size={'1'}
          weight={'medium'}
          className={'color-white'}
        >
          Balance
        </Text>

        <Flex
          align={'center'}
          gap={'1'}
        >
          <TetherToken
            className='color-yellow'
            size='2.5rem'
          />

          <Text
            size={'3'}
            weight={'regular'}
            data-testid={DataTestIDs.balanceValueHeader}
          >
            {balanceFormat}
          </Text>
        </Flex>
      </Flex>

      <MintDialog />
    </Flex>
  )
}
