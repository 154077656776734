import { http, createConfig, webSocket } from 'wagmi'
import { arbitrumSepolia, mainnet } from 'wagmi/chains'
import { WSS_PROVIDER_URL } from 'app/constants'
import { createClient, createPublicClient } from 'viem'

export const APP_CHAIN = arbitrumSepolia

export const wagmiConfig = createConfig({
  chains: [APP_CHAIN, mainnet],
  // transports: {
  //   [APP_CHAIN.id]: http(),
  //   [mainnet.id]: http()
  // },
  client: ({ chain }) => createClient({ chain, transport: http() })
})

export const wagmiWsConfig = createConfig({
  chains: [APP_CHAIN, mainnet],
  client: ({ chain }) =>
    createClient({ chain, transport: webSocket(WSS_PROVIDER_URL) })
})
