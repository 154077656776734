// TODO: add cross linking with the router paths constant
export const FOOTER_LINKS = [
  {
    title: 'PLATFORM',
    links: [
      {
        name: 'About',
        path: '/about'
      },
      {
        name: 'Help Center',
        path: '/onboarding'
      },
      {
        name: 'FAQ',
        path: '/help'
      }
    ]
  },
  {
    title: 'COMMUNITY',
    links: [
      {
        name: 'X (Twitter)',
        path: 'https://x.com/xyro_io'
      },
      {
        name: 'Discord',
        path: 'https://discord.gg/xyro'
      },
      {
        name: 'Telegram',
        path: 'https://t.me/xyro_io'
      },
      {
        name: 'Medium',
        path: 'https://xyro.medium.com'
      }
      // {
      //   name: 'Instagram',
      //   path: ''
      // },
      // TODO: temporal hiding; return when the legal pages are ready
      // {
      //   title: 'LEGAL',
      //   links: [
      //     {
      //       name: 'Privacy Policy',
      //       path: '/privacy-policy'
      //     },
      //     {
      //       name: 'AML Policy',
      //       path: '/aml-policy'
      //     },
      //     {
      //       name: 'Terms and conditions',
      //       path: '/terms-and-conditions'
      //     }
      //   ]
      // },
    ]
  }
]

export const SERVICE_DESCRIPTIONS = [
  'XYRO is a gamified social trading platform built to make crypto accessible and attractive. Users can test their ability to forecast cryptocurrency prices, win rewards, compete with others, and share trading ideas.',
  'Combining gaming and social features, XYRO offers a unique blend of GameFi and SocialFi: the first platform of its kind.'
]

export const CHAT_SIDEBAR_BUTTON_ID = 'chat_sidebar_button_id'
