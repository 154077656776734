import { useMemo } from 'react'
import * as RadixNav from '@radix-ui/react-navigation-menu'
import { Flex, Text, Tooltip } from '@radix-ui/themes'
import cn from 'classnames'
import { Link as RouterLink, useLocation } from 'react-router-dom'
import { DataTestIDs } from 'shared/constants'
import { ICON_SIZE, MODE_TYPE_TO_ICON_MAP } from '../constants'
import { NavigationRouteType } from '../types'
import styles from '../navigation.module.scss'

interface Props {
  to: string
  iconType: NavigationRouteType
  disabled?: boolean
  size?: number
  title?: string
  dataTestID?: DataTestIDs
}
export const NavigationLink: React.FC<Props> = ({
  to,
  iconType,
  size = ICON_SIZE,
  disabled = false,
  title = '',
  dataTestID = ''
}) => {
  const location = useLocation()
  const isActiveLink = location.pathname === to
  const Icon = useMemo(
    () => MODE_TYPE_TO_ICON_MAP?.[iconType] || null,
    [iconType]
  )
  const tooltipContent = disabled ? 'Please sign in' : iconType

  const navLinkClassNames = cn(styles.navigationMenuLink, {
    [styles.disabled]: disabled,
    [styles.navLinkActive]: isActiveLink
  })

  return (
    <Tooltip
      content={tooltipContent}
      delayDuration={100}
      side='right'
      className={styles.navLinkTooltip}
    >
      <RadixNav.Item>
        <Flex
          direction={'column'}
          align={'center'}
          gap={'0'}
          data-testid={dataTestID}
        >
          {Boolean(!disabled && Icon) ?
            <RouterLink
              to={to}
              className={styles.navLink}
            >
              <Icon
                width={size}
                height={size}
                className={navLinkClassNames}
              />
            </RouterLink>
          : <>
              <Icon
                width={size}
                height={size}
                className={navLinkClassNames}
              />
            </>
          }
          <RouterLink
            to={to}
            className={styles.navLinkText}
          >
            <Text
              align={'center'}
              size={'1'}
              className={cn('select-none', 'cursor-pointer', {
                [styles.linkDescriptionActive]: isActiveLink,
                'color-gray': !isActiveLink
              })}
              weight={'light'}
            >
              {title}
            </Text>
          </RouterLink>
        </Flex>
      </RadixNav.Item>
    </Tooltip>
  )
}
